import { render } from "react-dom";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";
import "./assets/styles/index.sass";

render(
    <BrowserRouter>
        <Router />
    </BrowserRouter>,
    document.getElementById("root")
);
