import NotFoundDesktop from "../assets/img/PageNotFoundDesktop.svg";
import { Link } from "react-router-dom";

const PageNotFound = () => {
    return (
        <div id="pageNotFoundWrapper">
            <div id="pageNotFoundContent">
                <img src={NotFoundDesktop} alt="This page cannot be found" />

                <div>Well, I'd like to say 'yes', but the computer says 'no' <br></br>
                to the content you have just requested.</div>
                <Link to="/">Just ring a bell at the front door</Link>
            </div>
        </div>
    )
}

export default PageNotFound
