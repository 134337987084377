import {useState, useEffect, useRef} from 'react';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import { Minus, Plus } from "../assets/img/Icons";
/* import useIntersection from "../utils/useIntersection"; */

const Home = () => {
    /* const ref = useRef(); */
    /* const inViewport = useIntersection(ref, '0px'); */
    const apiEndpoint = 'https://above-the-fold.cdn.prismic.io/api/v2';
    const Client = Prismic.client(apiEndpoint);
    const [doc, setDocData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                Prismic.Predicates.at('document.type', 'homepage'),
                { 'fetchLinks': ['portfolio.title', 'portfolio.uid' ] }
            )
            if (response) {
                setDocData(response.results[0])
            }
        }
        fetchData()
    }, [])

    if (!doc) {
        return false;
    }

    /* if (inViewport) {
        console.log('in viewport:', ref.current);
    } */

    console.log(doc.data.body);

    return (
        <div id="content">
            {doc.data.body.map((slice, i) => {
                return (
                    <>
                        <div className="section" key={i} type={slice.slice_type} id={slice.primary.section_id ? slice.primary.section_id[0].text : ""}>
                            {slice.slice_type === 'hero_text' &&
                                <div className="sliceWrapper">
                                    {RichText.render(slice.primary.text)}
                                </div>
                            }

                            {slice.slice_type === 'image-text-combo' &&
                                <>
                                    <div className="sliceLeft">
                                        {slice.primary.text_alignment === true
                                            ?
                                                <div className="text">
                                                    {RichText.render(slice.primary.text)}
                                                </div>
                                            :
                                            <>
                                                {slice.primary.image.url
                                                ?
                                                    <div className="image" background={slice.primary.image_background} style={{ backgroundImage: 'url(' + slice.primary.image.url + ')', backgroundColor: slice.primary.image_background }}></div>
                                                :
                                                    <div className="image"></div>
                                                }
                                            </>
                                        }
                                    </div>
                                        
                                    <div className="sliceRight">
                                        {slice.primary.text_alignment === false
                                            ?
                                                <div className="text">
                                                    {RichText.render(slice.primary.text)}
                                                </div>
                                            :
                                            <>
                                                {slice.primary.image.url
                                                    ?
                                                        <div className="image" background={slice.primary.image_background} style={{ backgroundImage: 'url(' + slice.primary.image.url + ')', backgroundColor: slice.primary.image_background }}></div>
                                                    :
                                                        <div className="image"></div>
                                                }
                                            </>
                                        }                                       
                                    </div>
                                </>
                            }

                            {slice.slice_type === 'super_powers_list' &&
                                <>
                                    <div className="sliceLeft">
                                        {slice.primary.text_alignment === true
                                            ?
                                            <div className="text">
                                                {RichText.render(slice.primary.text)}
                                                
                                                {slice.items.map((power, i) => (
                                                    <div className="superPowerItem">
                                                        <div className="superPowerLabel">
                                                            <Plus />
                                                            {power.accordion_label[0].text}
                                                        </div>

                                                        <div className="superPowerContent">
                                                            {RichText.render(power.accordion_text)}
                                                        </div>
                                                    </div>
                                                ))}
                                                
                                            </div>
                                            :
                                            <>
                                                {slice.primary.image.url
                                                    ?
                                                        <div className="image" background={slice.primary.image_background} style={{ backgroundImage: 'url(' + slice.primary.image.url + ')', backgroundColor: slice.primary.image_background }}></div>
                                                    :
                                                        <div className="image"></div>
                                                }
                                            </>
                                        }
                                    </div>

                                    <div className="sliceRight">
                                        {slice.primary.text_alignment === false
                                            ?
                                            <div className="text">
                                                {RichText.render(slice.primary.text)}

                                                {slice.items.map((power, i) => (
                                                    <div className="superPowerItem" key={i}>
                                                        <div className="superPowerLabel">
                                                            <Plus />
                                                            {power.accordion_label[0].text}
                                                        </div>

                                                        <div className="superPowerContent">
                                                            {RichText.render(power.accordion_text)}
                                                        </div>                        
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            <>
                                                {slice.primary.image.url
                                                    ?
                                                        <div className="image" background={slice.primary.image_background} style={{ backgroundImage: 'url(' + slice.primary.image.url + ')', backgroundColor: slice.primary.image_background }}></div>
                                                    :
                                                        <div className="image"></div>
                                                }
                                            </>
                                        }
                                    </div>
                                </>
                            }

                            {slice.slice_type === 'projects_list' &&
                                <div className="sliceWrapper">
                                    {slice.items.map((project, i) => (
                                        <div className="projectItem" key={i}>

                                            <Link to={(project.project_type === true ? "/projects/" : "/playground/") + project.project_name.uid}>
                                                <div className="projectItemLabel">
                                                    {project.project_name.data.title[0].text}
                                                </div>

                                                <p>{project.project_label[0].text}</p>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                        
                        {slice.slice_type === 'hero_text' &&
                            <div className="stickyBorder"></div>
                        }
                    </>
                )
            })}
        </div>
    )
}

export default Home
