import { Link } from "react-router-dom";

const Icons = () => {
    return (
        <>
            <div id="iconNavWrapper">
                <Link className="iconNavItem" to="/asset-library/icons#business"><span>Business</span> <span>5</span></Link>
                <Link className="iconNavItem" to="/asset-library/icons#contact"><span>Contact</span> <span>12</span></Link>
                <Link className="iconNavItem" to="/asset-library/icons#electronics"><span>Electronics</span> <span>7</span></Link>
                <Link className="iconNavItem" to="/asset-library/icons#social-media"><span>Social Media</span> <span>22</span></Link>
                <Link className="iconNavItem" to="/asset-library/icons#tech-seo"><span>Tech & SEO</span> <span>10</span></Link>
                <Link className="iconNavItem" to="/asset-library/icons#logistics"><span>Logistics</span> <span>12</span></Link>
                <Link className="iconNavItem" to="/asset-library/icons#education"><span>Education</span> <span>7</span></Link>
                <Link className="iconNavItem" to="/asset-library/icons#sport"><span>Sport</span> <span>5</span></Link>
                <Link className="iconNavItem" to="/asset-library/icons#travel"><span>Travel</span> <span>5</span></Link>
                <Link className="iconNavItem" to="/asset-library/icons#ecology"><span>Ecology</span> <span>8</span></Link>
                <Link className="iconNavItem" to="/asset-library/icons#weather"><span>Weather</span> <span>10</span></Link>
                <Link className="iconNavItem" to="/asset-library/icons#construction"><span>Construction</span> <span>12</span></Link>
                <Link className="iconNavItem" to="/asset-library/icons#arrows"><span>Arrows</span> <span>24</span></Link>
                <Link className="iconNavItem" to="/asset-library/icons#food-drinks"><span>Food & Drinks</span> <span>20</span></Link>
                <Link className="iconNavItem" to="/asset-library/icons#smileys"><span>Smileys</span> <span>7</span></Link>
            </div>
            <div id="iconsWrapper">
                
            </div>
        </>
    )
}

export default Icons
