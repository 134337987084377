import { Link } from 'react-router-dom';
import Logo from "../assets/img/Above-The-Fold-Logo.svg";

const Header = () => {
    return (
        <>
            <div id="logo">
                <Link aria-label="Return to the landingpage" to="/">
                    <img src={Logo} alt="Above The Fold Logo" />
                </Link>
            </div>

            <div id="navigation">
                <Link aria-label="Hello there - nice to meet you" to="/#about">About</Link>
                <Link aria-label="Get inspired by some selected projects" to="/#projects">Projects</Link>
                <Link aria-label="Have a look at my hypothetical projects" to="/#playground">Playground</Link>
                <Link aria-label="Explore assets like Icon Sets, Brand Kits and graphical designs" to="/#asset-library">Asset Library</Link>
                <a aria-label="Get in touch with me" href="mailto:hello.abovethefold@gmail.com">Contact</a>
            </div>
        </>
    )
}

export default Header
