import { Link } from "react-router-dom";
import Icons from "../components/assetLib/Icons";
import Graphics from "../components/assetLib/Graphics";
import BrandKits from "../components/assetLib/BrandKits";

const AssetLibrary = (props) => {
    const lib = props.match.params.id;

    return (
        <div id="assetLibWrapper">
            <div id="assetLibNav">
                <Link className="assetLibNavItem active" to="/asset-library/icons">Icons</Link>
                <Link className="assetLibNavItem" to="/asset-library/graphics">Graphics</Link>
                <Link className="assetLibNavItem" to="/asset-library/brand-kits">Brand Kits</Link>
            </div>

            {lib === "icons"
            ?
                <Icons />
            :
                (lib === "graphics")
            ?
                <Graphics />
            :
                <BrandKits />
            }
        </div>
    )
}

export default AssetLibrary
