import { Switch, Route } from "react-router-dom";
import PageNotFound from "./routes/PageNotFound";
import Home from "./routes/Home";
import Portfolio from "./routes/Portfolio";
import Header from "./components/Header";
import AssetLibrary from "./routes/AssetLibrary";

const Router = () => {
    return (
        <>
            <header>
                <Header />
            </header>

            <div id="main">
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/(projects|playground)/:id" component={Portfolio} />
                    <Route exact path="/asset-library/:id" component={AssetLibrary} />
                    <Route component={PageNotFound} />
                </Switch>
            </div>
        </>
    )
}

export default Router
