import { useState, useEffect } from 'react';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';

const Portfolio = (props) => {
    const type = props.match.params[0];
    const slug = props.match.params.id;
    const apiEndpoint = 'https://above-the-fold.cdn.prismic.io/api/v2';
    const Client = Prismic.client(apiEndpoint);
    const [doc, setDocData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                Prismic.Predicates.at('my.portfolio.uid', slug),
            )
            if (response) {
                setDocData(response.results[0])
            }
        }
        fetchData();

        document.body.classList.add('portfolio');

        return function cleanup() {
            document.body.classList.remove('portfolio');
        };
    }, []);

    if (!doc) {
        return false;
    }

    console.log(doc);

    return (
        <div id="pfWrapper">
            <div id="pfHeroWrapper">
                <div id="pfHeroImage" style={{ backgroundImage: 'url(' + doc.data.image.url + ')' }}></div>
                <div id="pfHeroTextWrapper">
                    <div id="pfHeroText">
                        <h1>{doc.data.title[0].text}</h1>
                        <p>{doc.data.subline}</p>
                    </div>
                </div>
            </div>

            <div id="pfStickyBorder"></div>
        </div>
    )
}

export default Portfolio
